import { axios, cancelTokenHandler } from 'libs';
import { stringifyUrl } from 'utils';
import { Properties, ArticleTag, Results } from 'types';

export const articleTag = {
  getList: async ({ queryKey: [, query] }: Properties) => {
    const { data } = await axios.get<Results<ArticleTag>>(stringifyUrl(`articleTag`, query), {
      cancelToken: cancelTokenHandler(articleTag)(articleTag.getList.name),
    });

    return data;
  },
  get: async ({ id, ...params }: Properties) => {
    const { data } = await axios.get(stringifyUrl(`articleTag/${id}`, params), {
      cancelToken: cancelTokenHandler(articleTag)(articleTag.get.name),
    });

    return data;
  },
  create: async (params: Partial<ArticleTag>) => {
    const { data } = await axios.post(stringifyUrl(`articleTag`, null), params, {
      cancelToken: cancelTokenHandler(articleTag)(articleTag.create.name),
    });

    return data;
  },
  update: async ({ id, ...params }: Partial<ArticleTag>) => {
    const { data } = await axios.put(stringifyUrl(`articleTag/${id}`, null), params, {
      cancelToken: cancelTokenHandler(articleTag)(articleTag.update.name),
    });

    return data;
  },
  updatePartial: async ({ id, ...params }: Partial<ArticleTag>) => {
    const { data } = await axios.patch(stringifyUrl(`articleTag/${id}`, null), params, {
      cancelToken: cancelTokenHandler(articleTag)(articleTag.updatePartial.name),
    });

    return data;
  },
  delete: async (id: number) => {
    const { data } = await axios.delete(stringifyUrl(`articleTag/${id}`, null), {
      cancelToken: cancelTokenHandler(articleTag)(articleTag.delete.name),
    });

    return data;
  },
};
