import { axios, cancelTokenHandler } from 'libs';
import { stringifyUrl } from 'utils';
import { Properties, Article, Results, CompactArticle } from 'types';

export const articles = {
  getList: async ({ queryKey: [, query] }: Properties, headers?) => {
    const { data } = await axios.get<Results<Article>>(stringifyUrl(`article`, query), {
      headers,
      cancelToken: cancelTokenHandler(articles)(articles.getList.name),
    });

    return data;
  },
  getAll: async () => {
    const { data } = await axios.get<CompactArticle[]>(stringifyUrl(`article-list`));
    return data;
  },
  get: async ({ queryKey: [, { id, ...query }] }: Properties, headers?) => {
    const { data } = await axios.get(stringifyUrl(`article/${id}`, query), {
      headers,
      cancelToken: cancelTokenHandler(articles)(articles.get.name),
    });

    return data;
  },
  create: async (params: Partial<Article>) => {
    const { data } = await axios.post(stringifyUrl(`article`), params, {
      cancelToken: cancelTokenHandler(articles)(articles.create.name),
    });

    return data;
  },
  getRss: async ({ queryKey: [, query] }: Properties) => {
    const { data } = await axios.get<Results<Article>>(stringifyUrl(`article/rss`, query), {
      cancelToken: cancelTokenHandler(articles)(articles.getRss.name),
    });

    return data;
  },
  getLinks: async ({ queryKey: [, query] }: Properties) => {
    const { data } = await axios.get<Results<Article>>(stringifyUrl(`article/links`, query), {
      cancelToken: cancelTokenHandler(articles)(articles.getLinks.name),
    });

    return data;
  },
  getMenuChildren: async ({ queryKey: [, { id }] }: Properties) => {
    const { data } = await axios.get<Article[]>(stringifyUrl(`article/${id}/menu_children`), {
      cancelToken: cancelTokenHandler(articles)(articles.getMenuChildren.name),
    });

    return data;
  },
  update: async ({ id, ...params }: Partial<Article>) => {
    const { data } = await axios.put(stringifyUrl(`article/${id}`), params, {
      cancelToken: cancelTokenHandler(articles)(articles.update.name),
    });

    return data;
  },
  updatePartial: async ({ id, ...params }: Partial<Article>) => {
    const { data } = await axios.patch(stringifyUrl(`article/${id}`), params, {
      cancelToken: cancelTokenHandler(articles)(articles.updatePartial.name),
    });

    return data;
  },
  delete: async (id: number) => {
    const { data } = await axios.delete(stringifyUrl(`article/${id}`), {
      cancelToken: cancelTokenHandler(articles)(articles.delete.name),
    });

    return data;
  },
};
