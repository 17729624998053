import { axios, cancelTokenHandler } from 'libs';
import { stringifyUrl } from 'utils';
import { Properties, Package, Results } from 'types';

export const packages = {
  getList: async ({ queryKey: [, query] }: Properties) => {
    const { data } = await axios.get<Results<Package>>(
      stringifyUrl(
        `${process.env.NEXT_PUBLIC_POSTAL_OFFICES}/public/nomenclatures/packages`,
        query,
      ),
      {
        cancelToken: cancelTokenHandler(packages)(packages.getList.name),
      },
    );

    return data;
  },
};
