interface Routes {
  [routeName: string]: {
    [lang: string]: { [paramKey: string]: { [paramName: string]: string | boolean } };
  };
}

export const routes: Routes = {
  index: { ro: {}, ru: {}, en: {} },

  //abonament

  leadership: {
    ro: { name: { default: 'Conducerea' } },
    ru: { name: { default: 'Лидерство' } },
    en: { name: { default: 'Leadership' } },
  },

  //buletin

  history: {
    ro: { name: { default: 'Istorie' } },
    ru: { name: { default: 'История' } },
    en: { name: { default: 'History' } },
  },

  //news

  // n: {
  //   ro: {
  //     name: { default: 'noutati' },
  //     tab: {
  //       all_topics: 'toate',
  //       business: 'business',
  //       economy: 'economic',
  //       juridical: 'juridic',
  //       financial: 'finante',
  //     },
  //     pageName: { default: 'pagina' },
  //     page: { parse: false },
  //   },

  //   ru: {
  //     name: { default: 'novosti' },
  //     tab: {
  //       all_topics: 'vse',
  //       business: 'biznes',
  //       economy: 'ekonomika',
  //       juridical: 'iuridi4eskie',
  //       financial: 'finansirovanie',
  //     },
  //     pageName: { default: 'stranitsa' },
  //     page: { parse: false },
  //   },

  //   en: {
  //     name: { default: 'noutati' },
  //     tab: {
  //       all_topics: 'toate',
  //       business: 'business',
  //       economy: 'economic',
  //       juridical: 'juridic',
  //       financial: 'finante',
  //     },
  //     pageName: { default: 'pagina' },
  //     page: { parse: false },
  //   },
  // },

  // article
  // ar: {
  //   ro: { name: { parse: false } },
  //   ru: { name: { parse: false } },
  //   en: { name: { parse: false } },
  // },
};
