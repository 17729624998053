import $axios, { CancelToken } from 'axios';
import { defaultLocale } from 'context/Dictionary';

export const axios = $axios.create();

export const cancelToken = $axios.CancelToken;

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.baseURL = process.env.NEXT_PUBLIC_API_URL;

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if ($axios.isCancel(error)) {
      return Promise.reject({ cancel: true, message: 'The endpoint was cancelled' });
    }

    if (error.response) {
      // FIXME: Improve error handlers
      // if (
      //   error.response.status === 403 &&
      //   error.response.data.detail === 'You do not have permission to perform this action.'
      // ) {
      //   history.push(getRoute(routes, 'IndexEntry', { query: { permissions_guard: true } }));
      // }
    }

    return Promise.reject(error);
  },
);

export const axiosHeadersUpdater = (lang: string) => {
  axios.defaults.headers['Accept-Language'] = lang;
};

axiosHeadersUpdater(defaultLocale);

// FIXME: Fix the interfaces
type GenericObject = { [key: string]: any };

export const createCancelTokenHandler = (apiObject: GenericObject): GenericObject => {
  // initializing the cancel token handler object
  const cancelTokenHandler: GenericObject = {};

  // for each property in apiObject, i.e. for each request
  Object.getOwnPropertyNames(apiObject).forEach((propertyName) => {
    // initializing the cancel token of the request
    const cancelTokenRequestHandler: { cancelToken?: GenericObject } = {};

    // associating the cancel token handler to the request name
    cancelTokenHandler[propertyName] = {
      handleRequestCancellation: () => {
        // if a previous cancel token exists,
        // cancel the request
        cancelTokenRequestHandler.cancelToken &&
          cancelTokenRequestHandler.cancelToken.cancel(`${propertyName} canceled`);

        // creating a new cancel token
        cancelTokenRequestHandler.cancelToken = cancelToken.source();

        // returning the new cancel token
        return cancelTokenRequestHandler.cancelToken;
      },
    };
  });

  return cancelTokenHandler;
};

// Create cancel token by name
export const cancelTokenHandler = (apiObject: GenericObject) => {
  const cancelTokenHandlerObject = createCancelTokenHandler(apiObject);

  // FIXME: Why handleRequestCancellation is undefined on deploy???
  return (name: string): CancelToken =>
    cancelTokenHandlerObject[name]?.handleRequestCancellation().token;
};
