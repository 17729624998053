import { axios, cancelTokenHandler } from 'libs';
import { stringifyUrl } from 'utils';
import { Properties, User } from 'types';

import { resetPassword } from './resetPassword';

export const user = {
  getList: async ({ queryKey: [, query] }: Properties) => {
    const { data } = await axios.get(stringifyUrl(`user`, query), {
      cancelToken: cancelTokenHandler(user)(user.getList.name),
    });

    return data;
  },
  get: async ({ queryKey: [, { id, ...query }] }: Properties) => {
    const { data } = await axios.get(stringifyUrl(`user/${id}`, query), {
      cancelToken: cancelTokenHandler(user)(user.get.name),
    });

    return data;
  },
  getProfile: async () => {
    const { data } = await axios.get(stringifyUrl(`user/profile`), {
      cancelToken: cancelTokenHandler(user)(user.getProfile.name),
    });

    return data;
  },
  getRole: async () => {
    const { data } = await axios.get(stringifyUrl(`user/role`), {
      cancelToken: cancelTokenHandler(user)(user.getRole.name),
    });

    return data;
  },
  create: async (params: Partial<User>) => {
    const { data } = await axios.post(stringifyUrl(`user`), params, {
      cancelToken: cancelTokenHandler(user)(user.create.name),
    });

    return data;
  },
  update: async ({ id, ...params }: Partial<User>) => {
    const { data } = await axios.put(stringifyUrl(`user/${id}`), params, {
      cancelToken: cancelTokenHandler(user)(user.update.name),
    });

    return data;
  },
  updatePartial: async ({ id, ...params }: Partial<User>) => {
    const { data } = await axios.patch(stringifyUrl(`user/${id}`), params, {
      cancelToken: cancelTokenHandler(user)(user.updatePartial.name),
    });

    return data;
  },
  delete: async (id: number) => {
    const { data } = await axios.delete(stringifyUrl(`user/${id}`), {
      cancelToken: cancelTokenHandler(user)(user.delete.name),
    });

    return data;
  },
  ...resetPassword,
};
