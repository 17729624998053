import { axios, cancelTokenHandler } from 'libs';
import { stringifyUrl } from 'utils';
import { Properties } from 'types';

export const common = {
  getHealth: async ({ queryKey: [, query] }: Properties) => {
    const { data } = await axios.get(stringifyUrl(`common/health`, query), {
      cancelToken: cancelTokenHandler(common)(common.getHealth.name),
    });

    return data;
  },
  getProtected: async ({ queryKey: [, query] }: Properties) => {
    const { data } = await axios.get(stringifyUrl(`common/protected`, query), {
      cancelToken: cancelTokenHandler(common)(common.getProtected.name),
    });

    return data;
  },
};
