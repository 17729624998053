import { axios, cancelTokenHandler } from 'libs';
import { stringifyUrl } from 'utils';
import { Auth, RefreshToken, VerifyToken } from 'types';

export const token = {
  getToken: async (params: Auth) => {
    const { data } = await axios.post(stringifyUrl(`auth/token/obtain`, null), params, {
      cancelToken: cancelTokenHandler(token)(token.getToken.name),
    });

    return data;
  },
  refreshToken: async (params: RefreshToken) => {
    const { data } = await axios.post(stringifyUrl(`auth/token/refresh`, null), params, {
      cancelToken: cancelTokenHandler(token)(token.refreshToken.name),
    });

    return data;
  },
  verifyToken: async (params: VerifyToken) => {
    const { data } = await axios.post(stringifyUrl(`auth/token/verify`, null), params, {
      cancelToken: cancelTokenHandler(token)(token.verifyToken.name),
    });

    return data;
  },
};
