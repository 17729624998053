import { axios, cancelTokenHandler } from 'libs';
import { stringifyUrl } from 'utils';
import { Institution, Properties, Results } from 'types';

export const institution = {
  getList: async ({ queryKey: [, query] }: Properties) => {
    const { data } = await axios.get<Results<Institution>>(stringifyUrl(`institution`, query), {
      cancelToken: cancelTokenHandler(institution)(institution.getList.name),
    });

    return data;
  },
  get: async ({ id, ...params }: Properties) => {
    const { data } = await axios.get(stringifyUrl(`institution/${id}`, params), {
      cancelToken: cancelTokenHandler(institution)(institution.get.name),
    });

    return data;
  },
  create: async (params: Partial<Institution>) => {
    const { data } = await axios.post(stringifyUrl(`institution`, null), params, {
      cancelToken: cancelTokenHandler(institution)(institution.create.name),
    });

    return data;
  },
  update: async ({ id, ...params }: Partial<Institution>) => {
    const { data } = await axios.put(stringifyUrl(`institution/${id}`, null), params, {
      cancelToken: cancelTokenHandler(institution)(institution.update.name),
    });

    return data;
  },
  updatePartial: async ({ id, ...params }: Partial<Institution>) => {
    const { data } = await axios.patch(stringifyUrl(`institution/${id}`, null), params, {
      cancelToken: cancelTokenHandler(institution)(institution.updatePartial.name),
    });

    return data;
  },
  delete: async (id: number) => {
    const { data } = await axios.delete(stringifyUrl(`institution/${id}`, null), {
      cancelToken: cancelTokenHandler(institution)(institution.delete.name),
    });

    return data;
  },
};
