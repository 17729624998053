// eslint-disable-next-line import/default
import { defaultLocale } from 'context/Dictionary';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/ro';
import 'dayjs/locale/ru';

dayjs.extend(utc);
dayjs.extend(customParseFormat);

export const timeFormat = 'HH:mm';
export const timeSecondsFormat = 'HH:mm:ss';
export const dateFormat = 'YYYY-MM-DD';
export const dateFormatAPI = 'YYYY-MM-DD';
export const dateTimeFormat = 'YYYY-MM-DD HH:mm';
export const dateFormatInput = 'yyyy-MM-dd'; //format for date-fns
export const dateTimeFormatInput = 'yyyy-MM-dd HH:mm'; //format for date-fns
export const dateTimeFormatLetters = 'D MMMM YYYY'; //format with letters

export const format = (date?: Date | string, format?: string): string =>
  dayjs(date).format(format || dateFormat);

export const formatLocale = (date?: Date | string, format?: string, locale?: string): string =>
  dayjs(date)
    .locale(locale || defaultLocale)
    .format(format || dateFormat);

export const toISO = (date: Date | string): string => new Date(date).toISOString();
export const getYear = (date: Date | string): string => dayjs(date).utc().format('YYYY');
export const getMonth = (date: Date | string): string => dayjs(date).utc().format('MMM');
export const getFullMonth = (date: Date | string): string => dayjs(date).utc().format('MMMM');
export const getDay = (date: Date | string): string => dayjs(date).utc().format('D');
export const getTime = (date: Date | string): string => dayjs(date).utc().format('HH:mm');

export const isAfter = (date: Date | string): boolean => dayjs().utc().isAfter(date);
export const isBefore = (date: Date | string): boolean => dayjs().utc().isBefore(date);
export const add = (count: number): dayjs.Dayjs => dayjs().utc().add(count, 'day');

export const yearBirthday = (years: number): any => dayjs().utc().subtract(years, 'year');

export const formatHours = (time: string | null) => {
  return time ? dayjs(time, [timeFormat, timeSecondsFormat]).format(timeFormat) : time;
};

export const getWorkHours = (from: string | null, to: string | null) =>
  from || to
    ? [from && formatHours(from), to && formatHours(to)].filter((i) => i).join(' - ')
    : '-';
