import { axios, cancelTokenHandler } from 'libs';
import { stringifyUrl } from 'utils';
import { Dictionary, Properties, Results } from 'types';

export const dictionary = {
  getList: async ({ queryKey: [, query] }: Properties, headers?) => {
    const { data } = await axios.get<Results<Dictionary>>(stringifyUrl(`dictionary`, query), {
      headers,
      cancelToken: cancelTokenHandler(dictionary)(dictionary.getList.name),
    });

    return data;
  },
  get: async ({ id, ...params }: Properties) => {
    const { data } = await axios.get(stringifyUrl(`dictionary/${id}`, params), {
      cancelToken: cancelTokenHandler(dictionary)(dictionary.get.name),
    });

    return data;
  },
  create: async (params: Partial<Dictionary>) => {
    const { data } = await axios.post(stringifyUrl(`dictionary`, null), params, {
      cancelToken: cancelTokenHandler(dictionary)(dictionary.create.name),
    });

    return data;
  },
  update: async ({ id, ...params }: Partial<Dictionary>) => {
    const { data } = await axios.put(stringifyUrl(`dictionary/${id}`, null), params, {
      cancelToken: cancelTokenHandler(dictionary)(dictionary.update.name),
    });

    return data;
  },
  updatePartial: async ({ id, ...params }: Partial<Dictionary>, headers?) => {
    const { data } = await axios.patch(stringifyUrl(`dictionary/${id}`, null), params, {
      headers,
      // cancelToken: cancelTokenHandler(dictionary)(dictionary.updatePartial.name),
    });

    return data;
  },
  delete: async (id: number) => {
    const { data } = await axios.delete(stringifyUrl(`dictionary/${id}`, null), {
      cancelToken: cancelTokenHandler(dictionary)(dictionary.delete.name),
    });

    return data;
  },
};
