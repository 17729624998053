import { Supercluster } from 'supercluster';

import { PostalOfficeType } from 'types';

export const pointItems = [
  {
    type: PostalOfficeType.POST_OFFICE,
    color: 'blue',
  },
  {
    type: PostalOfficeType.POST_AGENCY,
    color: 'green',
  },
  {
    type: PostalOfficeType.POST_CENTER,
    color: 'light-green',
  },
  {
    type: PostalOfficeType.POST_TERMINAL,
    color: 'orange',
  },
  {
    type: PostalOfficeType.TRANSIT_CENTER,
    color: 'grey',
  },
  {
    type: PostalOfficeType.FAST_COURIER_CENTER,
    color: 'red',
  },
  // {
  //   type: PostalOfficeType.DEPOSIT,
  //   color: 'yellow',
  // },
];

export const getListLocation = (id: number, supercluster: Supercluster) => {
  const listLocation: number[] = [];
  const get = (id: number) => {
    const arrCluster = supercluster?.getChildren(id);

    arrCluster.forEach((e) => {
      if (e.properties.cluster) {
        get(e.properties.cluster_id);
      } else {
        listLocation.push(e.id);
      }
    });
  };
  get(id);
  return listLocation;
};
