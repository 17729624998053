import axios from 'axios';
import { MarketProduct, MarketProductCategory, Properties, MainApiResults } from 'types';
import { stringifyUrl } from 'utils';

export const market = {
  products: {
    getList: async ({ queryKey: [, query] }: Properties, headers?) => {
      const { data } = await axios.get<MainApiResults<MarketProduct>>(
        stringifyUrl(`${process.env.NEXT_PUBLIC_MAIN_POSTAL_OFFICES}/market/products`, query),
        {
          headers,
        },
      );

      return data;
    },

    get: async ({ queryKey: [, { id, ...query }] }: Properties, headers?) => {
      const { data } = await axios.get<MarketProduct>(
        stringifyUrl(`${process.env.NEXT_PUBLIC_MAIN_POSTAL_OFFICES}/market/products/${id}`, query),
        {
          headers,
        },
      );

      return data;
    },

    categories: {
      getList: async ({ queryKey: [, query] }: Properties, headers?) => {
        const { data } = await axios.get<MainApiResults<MarketProductCategory>>(
          stringifyUrl(
            `${process.env.NEXT_PUBLIC_MAIN_POSTAL_OFFICES}/market/products/categories`,
            query,
          ),
          {
            headers,
          },
        );

        return data;
      },
    },
  },
};
