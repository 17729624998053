import { axios, cancelTokenHandler } from 'libs';
import { stringifyUrl } from 'utils';
import { Properties, Country, Results } from 'types';

export const countries = {
  getList: async ({ queryKey: [, query] }: Properties) => {
    const { data } = await axios.get<Results<Country>>(
      stringifyUrl(`${process.env.NEXT_PUBLIC_MAIN_POSTAL_OFFICES}/nomenclatures/countries`, query),
      {
        cancelToken: cancelTokenHandler(countries)(countries.getList.name),
      },
    );

    return data;
  },
};
