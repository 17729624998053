import { dehydrate, QueryClient } from 'react-query';
import { Properties } from 'types';

export const getQueries = (queryClient: QueryClient): Properties =>
  dehydrate(queryClient).queries.reduce(
    (prevState, { state: { data }, queryHash }) => ({
      ...prevState,
      [JSON.parse(queryHash)[0]]: data,
    }),
    {},
  );
