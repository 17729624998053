import { axios, cancelTokenHandler } from 'libs';
import { stringifyUrl } from 'utils';
import { Properties, Results, UsefulLink } from 'types';

export const usefulLinks = {
  getList: async ({ queryKey: [, query] }: Properties) => {
    const { data } = await axios.get<Results<UsefulLink>>(stringifyUrl(`usefulLinks`, query), {
      cancelToken: cancelTokenHandler(usefulLinks)(usefulLinks.getList.name),
    });

    return data;
  },
  get: async ({ id, ...params }: Properties) => {
    const { data } = await axios.get(stringifyUrl(`usefulLinks/${id}`, params), {
      cancelToken: cancelTokenHandler(usefulLinks)(usefulLinks.get.name),
    });

    return data;
  },
  create: async (params: Partial<UsefulLink>) => {
    const { data } = await axios.post(stringifyUrl(`usefulLinks`), params, {
      cancelToken: cancelTokenHandler(usefulLinks)(usefulLinks.create.name),
    });

    return data;
  },
  delete: async (id: number) => {
    const { data } = await axios.delete(stringifyUrl(`usefulLinks/${id}`), {
      cancelToken: cancelTokenHandler(usefulLinks)(usefulLinks.delete.name),
    });

    return data;
  },
};
