export function addOrRemove<T>(array: T[], item: T) {
  const exists = array.includes(item);

  if (exists) {
    return array.filter((c) => c !== item);
  }

  const result = array.slice();
  result.push(item);

  return result;
}

export const indexed = (arr: any[]): any[] => arr && arr.map((item, key) => ({ ...item, key }));

export const indexedFirst = (arr: any[]): any[] =>
  arr && arr.map((item, key) => ({ ...item, key: key + 1 }));

export const isEqual = (a, b): boolean => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};
