import { axios, cancelTokenHandler } from 'libs';
import { stringifyUrl } from 'utils';
import { Properties, Region, Results } from 'types';

export const regions = {
  getList: async ({ queryKey: [, query] }: Properties) => {
    const { data } = await axios.get<Results<Region>>(
      stringifyUrl(`${process.env.NEXT_PUBLIC_MAIN_POSTAL_OFFICES}/nomenclatures/regions`, query),
      {
        cancelToken: cancelTokenHandler(regions)(regions.getList.name),
      },
    );

    return data;
  },
};
