import { axios, cancelTokenHandler } from 'libs';
import { stringifyUrl } from 'utils';
import { Properties } from 'types';

export const users = {
  addUser: async (params: Properties) => {
    const { data } = await axios.post(stringifyUrl(`application/users`, null, 2), params, {
      cancelToken: cancelTokenHandler(users)(users.addUser.name),
    });

    return data;
  },
  deleteUser: async (ids: number[]) => {
    const { data } = await axios.delete(stringifyUrl(`application/users`, { users_id: ids }, 2), {
      cancelToken: cancelTokenHandler(users)(users.deleteUser.name),
    });

    return data;
  },
};
