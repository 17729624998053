import { axios, cancelTokenHandler } from 'libs';
import { stringifyUrl } from 'utils';
import {
  Properties,
  Country,
  Delivery,
  DeliveryRegions,
  AdditionalServices,
  Results,
  ShippingType,
  DeliveryLimitation,
} from 'types';

export const nomenclatures = {
  getShippingTypes: async ({ queryKey: [, query] }: Properties) => {
    const { data } = await axios.get<Results<ShippingType>>(
      stringifyUrl(
        `${process.env.NEXT_PUBLIC_SENDING_POSTAL_OFFICES}/public/nomenclatures/shipping-types`,
        query,
      ),
      {
        cancelToken: cancelTokenHandler(nomenclatures)(nomenclatures.getShippingTypes.name),
      },
    );

    return data;
  },
  getDeliveryMethods: async ({ queryKey: [, query] }: Properties) => {
    const { data } = await axios.get<Results<Delivery>>(
      stringifyUrl(
        `${process.env.NEXT_PUBLIC_SENDING_POSTAL_OFFICES}/public/nomenclatures/delivery-methods`,
        query,
      ),
      {
        cancelToken: cancelTokenHandler(nomenclatures)(nomenclatures.getDeliveryMethods.name),
      },
    );

    return data;
  },
  getDeliveryRegion: async ({ queryKey: [, query] }: Properties) => {
    const { data } = await axios.get<Results<DeliveryRegions>>(
      stringifyUrl(
        `${process.env.NEXT_PUBLIC_SENDING_POSTAL_OFFICES}/public/nomenclatures/delivery-regions`,
        query,
      ),
      {
        cancelToken: cancelTokenHandler(nomenclatures)(nomenclatures.getDeliveryRegion.name),
      },
    );

    return data;
  },
  getDeliveryPriorities: async ({ queryKey: [, query] }: Properties) => {
    const { data } = await axios.get<Results<Delivery>>(
      stringifyUrl(
        `${process.env.NEXT_PUBLIC_SENDING_POSTAL_OFFICES}/public/nomenclatures/delivery-priorities`,
        query,
      ),
      {
        cancelToken: cancelTokenHandler(nomenclatures)(nomenclatures.getDeliveryPriorities.name),
      },
    );

    return data;
  },
  getCountries: async ({ queryKey: [, query] }: Properties) => {
    const { data } = await axios.get<Results<Country>>(
      stringifyUrl(
        `${process.env.NEXT_PUBLIC_SENDING_POSTAL_OFFICES}/public/nomenclatures/countries`,
        query,
      ),
      {
        cancelToken: cancelTokenHandler(nomenclatures)(nomenclatures.getCountries.name),
      },
    );

    return data;
  },
  getAdditionalServices: async ({ queryKey: [, query] }: Properties) => {
    const { data } = await axios.get<Results<AdditionalServices>>(
      stringifyUrl(
        `${process.env.NEXT_PUBLIC_SENDING_POSTAL_OFFICES}/public/nomenclatures/additional-services`,
        query,
      ),
      {
        cancelToken: cancelTokenHandler(nomenclatures)(nomenclatures.getAdditionalServices.name),
      },
    );

    return data;
  },

  getLimitation: async ({ queryKey: [, query] }: Properties) => {
    const { data } = await axios.get<DeliveryLimitation>(
      stringifyUrl(
        `${process.env.NEXT_PUBLIC_SENDING_POSTAL_OFFICES}/public/nomenclatures/limitations`,
        query,
      ),
      {
        cancelToken: cancelTokenHandler(nomenclatures)(nomenclatures.getAdditionalServices.name),
      },
    );

    return data;
  },
};
