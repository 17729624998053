import { axios, cancelTokenHandler } from 'libs';
import { stringifyUrl } from 'utils';
import { Properties, Results, Support } from 'types';

export const support = {
  getList: async ({ queryKey: [, query] }: Properties) => {
    const { data } = await axios.get<Results<Support>>(stringifyUrl(`support`, query), {
      cancelToken: cancelTokenHandler(support)(support.getList.name),
    });

    return data;
  },
  get: async ({ id, ...params }: Properties) => {
    const { data } = await axios.get(stringifyUrl(`support/${id}`, params), {
      cancelToken: cancelTokenHandler(support)(support.get.name),
    });

    return data;
  },
  create: async (params: Partial<Support>) => {
    const { data } = await axios.post(stringifyUrl(`support`, null), params, {
      cancelToken: cancelTokenHandler(support)(support.create.name),
    });

    return data;
  },
  update: async ({ id, ...params }: Partial<Support>) => {
    const { data } = await axios.put(stringifyUrl(`support/${id}`, null), params, {
      cancelToken: cancelTokenHandler(support)(support.update.name),
    });

    return data;
  },
  updatePartial: async ({ id, ...params }: Partial<Support>) => {
    const { data } = await axios.patch(stringifyUrl(`support/${id}`, null), params, {
      cancelToken: cancelTokenHandler(support)(support.updatePartial.name),
    });

    return data;
  },
  delete: async (id: number) => {
    const { data } = await axios.delete(stringifyUrl(`support/${id}`, null), {
      cancelToken: cancelTokenHandler(support)(support.delete.name),
    });

    return data;
  },
};
