import { ArticleTag, ArticleTags, MenuItem, I18n } from 'types';

interface CurrentMenuOptions {
  slug?: string | string[];
  lang: string;
  path: string;
}

export const getCurrentMenu = (data: MenuItem[], { slug, lang, path }: CurrentMenuOptions) => {
  let currentMenu: MenuItem | MenuItem[] = [];

  const findBySlug = (child?: MenuItem[], parentItem?: MenuItem) => {
    child?.forEach(({ i18n, tags, children, ...props }) => {
      if (
        typeof slug === 'string'
          ? i18n[lang]?.slug === slug
          : path === getSlug({ i18n, tags }, lang)
      ) {
        return (currentMenu = parentItem ? { ...parentItem, children: child } : child);
      } else {
        return findBySlug(children, { i18n, tags, children, ...props });
      }
    });
  };

  findBySlug(data);

  return currentMenu;
};

export const getSlug = (
  { i18n, tags }: { i18n: I18n; tags: ArticleTag[] },
  lang: string,
): string => {
  const slug = i18n[lang]?.slug;

  if (
    tags.find((i) =>
      [
        ArticleTags.NEWS,
        ArticleTags.NEWS_HOME_PAGE,
        ArticleTags.NEWS_RELEVANCE,
        ArticleTags.ANNOUNCES,
        ArticleTags.WARNINGS,
      ].includes(i.code),
    )
  ) {
    return `/${lang}/news/${slug}`;
  }

  if (tags.find((i) => [ArticleTags.NEWS_PAGE].includes(i.code))) {
    return `/${lang}/news`;
  }

  if (tags.find((i) => [ArticleTags.ABOUT_PAGE].includes(i.code))) {
    return `/${lang}/about`;
  }

  if (tags.find((i) => [ArticleTags.CONTACTS].includes(i.code))) {
    return `/${lang}/contact/${slug}`;
  }

  if (
    tags.find((i) =>
      [ArticleTags.TEAM, ArticleTags.TEAM_MEMBER, ArticleTags.SCHEMA].includes(i.code),
    )
  ) {
    return `/${lang}/team/${slug}`;
  }

  if (tags.find((i) => [ArticleTags.VACANCIES_PAGE].includes(i.code))) {
    return `/${lang}/vacancies/${slug}`;
  }

  if (tags.find((i) => [ArticleTags.F_A_Q].includes(i.code))) {
    return `/${lang}/faq/${slug}`;
  }

  if (
    tags.find((i) =>
      [ArticleTags.PETITION, ArticleTags.COMPLAINT, ArticleTags.COMPLAINT_INTERNATIONAL].includes(
        i.code,
      ),
    )
  ) {
    return `/${lang}/complaint-petition/${slug}`;
  }

  if (tags.find((i) => [ArticleTags.COMPLAINT_PETITION].includes(i.code))) {
    return `/${lang}/complaint-petition`;
  }

  if (tags.find((i) => [ArticleTags.MAP].includes(i.code))) {
    return `/${lang}/map`;
  }

  if (tags.find((i) => [ArticleTags.TRACK_TRACE].includes(i.code))) {
    return `/${lang}/track-trace`;
  }

  if (tags.find((i) => [ArticleTags.TARIFF_CALCULATOR].includes(i.code))) {
    return `/${lang}/send`;
  }
  if (tags.find((i) => [ArticleTags.MAILING_CALCULATOR].includes(i.code))) {
    return `/${lang}/mailing`;
  }

  if (tags.find((i) => [ArticleTags.MANDATE_CALCULATOR].includes(i.code))) {
    return `/${lang}/mandate`;
  }

  if (tags.find((i) => [ArticleTags.FULLSCREEN_PAGE].includes(i.code))) {
    return `/${lang}/p/${slug}`;
  }

  if (tags.find((i) => [ArticleTags.MARKET].includes(i.code))) {
    return `/${lang}/market`;
  }

  if (tags.find((i) => [ArticleTags.PERIODIC_EDITIONS].includes(i.code))) {
    return `/${lang}/periodic-editions`;
  }

  return `/${lang}/page/${slug}`;
};
