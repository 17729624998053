import { useEffect, useReducer, useMemo } from 'react';
import { useRouter } from 'next/router';
import { useQuery } from 'react-query';
import { v1_jsonString } from 'api';
import { Locale } from 'types';
import { reducer, init } from './utils';
import { cacheTime } from 'utils';
import DictionaryContext, { defaultDictionaries } from './DictionaryContext';

// tslint:disable-next-line: interface-over-type-literal
export type DictionaryStoreType = Record<
  Locale,
  {
    /**
     * This is the namespace
     */
    [key: string]: {
      /**
       * This the value key
       */
      [key: string]: string;
    };
  }
>;

export const defaultLocale = Locale.RO;

export const locales: Locale[] = [Locale.RO, Locale.RU, Locale.EN];

export const getInitialLocale = (): Locale => {
  // preference from the previous session
  const localSetting = localStorage.getItem('locale');
  if (localSetting && isLocale(localSetting)) {
    return localSetting;
  }

  // the language setting of the browser
  const [browserSetting] = navigator.language.split('-');
  if (isLocale(browserSetting)) {
    return browserSetting;
  }

  return defaultLocale;
};

export const activeLocales = [Locale.RO, Locale.RU, Locale.EN];

export const isLocale = (tested: string): tested is Locale => {
  return activeLocales.some((locale) => locale === tested);
};

const storageKeyName = 'dictionary';

/**
 * Dictionary provider, use this once in the app component.
 */
const DictionaryProvider: React.FC = ({ children }) => {
  const [store, dispatch] = useReducer(reducer, { ...defaultDictionaries }, init);
  const router = useRouter();

  const { data: dataSettings } = useQuery(['settings'], v1_jsonString.getList, {
    cacheTime,
  });

  const languagesSettings = useMemo(
    () => dataSettings?.results?.find((i) => i.keyword === 'lang'),
    [dataSettings],
  );

  const languagesOptions = useMemo(
    () => languagesSettings && JSON.parse(languagesSettings?.value),
    [languagesSettings],
  );

  const activeLanguages = useMemo(() => {
    let lang = [];

    for (const key in languagesOptions) {
      if (languagesOptions[key]) {
        lang.push(key);
      }
    }

    return lang;
  }, [languagesOptions]);

  // Setup session storage
  useEffect(() => {
    if (window) {
      window.sessionStorage.setItem(storageKeyName, JSON.stringify(store));
    }

    return () => {
      /**
       * Cleanup the storage when the user refreshes the page.
       */
      if (window) {
        window.sessionStorage.removeItem(storageKeyName);
      }
    };
  }, []);

  // Save `store` to session storage.
  useEffect(() => {
    try {
      if (window) {
        const json = JSON.stringify(store);
        window.sessionStorage.setItem(storageKeyName, json);
      }
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') {
        // tslint:disable: no-console
        console.warn('Could not set dictionary in session storage');
        console.error(e);
        // tslint:enable: no-console
      }
    }
  }, [store]);

  const locale = useMemo(() => {
    return typeof router.query.lang === 'string' && isLocale(router.query.lang)
      ? router.query.lang
      : defaultLocale;
  }, [router.query.lang]);

  useEffect(() => {
    localStorage.setItem('locale', locale);
  }, [locale]);

  return (
    <DictionaryContext.Provider
      value={{
        activeLanguages,
        store,
        dispatch,
        loading: !Object.keys(store[locale]).length,
        locale,
      }}
    >
      {children}
    </DictionaryContext.Provider>
  );
};

export default DictionaryProvider;
