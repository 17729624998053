import { axios, cancelTokenHandler } from 'libs';
import { stringifyUrl } from 'utils';
import { Application, Properties } from 'types';

import { users } from './users';

export const applications = {
  get: async ({ queryKey: [, { id, ...query }] }: Properties) => {
    const { data } = await axios.get(stringifyUrl(`application/${id}`, query, 2), {
      cancelToken: cancelTokenHandler(applications)(applications.get.name),
    });

    return data;
  },
  update: async ({ id, ...params }: Partial<Application>) => {
    const { data } = await axios.put(stringifyUrl(`application/${id}`, null, 2), params, {
      cancelToken: cancelTokenHandler(applications)(applications.update.name),
    });

    return data;
  },
  updatePartial: async ({ id, ...params }: Partial<Application>) => {
    const { data } = await axios.patch(stringifyUrl(`application/${id}`, null, 2), params, {
      cancelToken: cancelTokenHandler(applications)(applications.updatePartial.name),
    });

    return data;
  },
  delete: async (id: number) => {
    const { data } = await axios.patch(stringifyUrl(`application/${id}`, null, 2), {
      cancelToken: cancelTokenHandler(applications)(applications.delete.name),
    });

    return data;
  },
  getCurrent: async ({ queryKey: [, query] }: Properties) => {
    const { data } = await axios.get(stringifyUrl(`application/current`, query, 2), {
      cancelToken: cancelTokenHandler(applications)(applications.getCurrent.name),
    });

    return data;
  },
  getTesting: async ({ queryKey: [, query] }: Properties) => {
    const { data } = await axios.get(stringifyUrl(`application/testing`, query, 2), {
      cancelToken: cancelTokenHandler(applications)(applications.getTesting.name),
    });

    return data;
  },
  create: async (params: Partial<Application>) => {
    const { data } = await axios.post(stringifyUrl(`application`, null, 2), params, {
      cancelToken: cancelTokenHandler(applications)(applications.create.name),
    });

    return data;
  },
  updatePartialCurrent: async (params: Partial<Application>) => {
    const { data } = await axios.patch(stringifyUrl(`application/update`, null, 2), params, {
      cancelToken: cancelTokenHandler(applications)(applications.updatePartialCurrent.name),
    });

    return data;
  },
  ...users,
};
