export const parseWebsite = (website?: string): string | '' =>
  website
    ? website
        .replace('www.', '')
        .replace('https://', '')
        .replace('http://', '')
        .replace('/', '')
        .toLowerCase()
    : '';

export const getLogotypeLink = (src?: string): string =>
  src
    ? `https://account.globaldatabase.com/logo/${src
        .replace('https://', '')
        .replace('http://', '')}/`
    : '';

export const firstLetters = (target?: string) =>
  target
    ? target
        .split(' ')
        .map((n) => n[0])
        .join('')
        .toUpperCase()
        .substr(0, 2)
    : '';

export const trimText = (text?: string, maxLength = 420) =>
  text && text.length > maxLength ? `${text.substring(0, maxLength)}...` : `${text}`;
