import { axios, cancelTokenHandler } from 'libs';
import { stringifyUrl } from 'utils';
import { Properties } from 'types';

export const uploadFile = {
  create: async (params: Properties) => {
    const { data } = await axios.post(stringifyUrl(`upload-file`), params, {
      cancelToken: cancelTokenHandler(uploadFile)(uploadFile.create.name),
    });

    return data;
  },
};
