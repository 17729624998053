export enum ServiceType {
  EXPRESS_SHIPPING = 'express_shipping',
  RETURN_DOCUMENTS = 'return_documents',
  DECLARED_AMOUNT = 'declared_amount',
  CASH_ON_DELIVERY = 'cash_on_delivery',
  ADDRESS_FORWARDING = 'address_forwarding',
  RETURN_ON_FAILED_DELIVERY = 'return_on_failed_delivery',
  KEEP_SENDING = 'keep_sending',
  PACKING_SERVICE = 'packing_service',
  OPEN_ON_SENDING = 'open_on_sending',
  INSURANCE = 'insurance',
  CUSTOM = 'custom',
}

export enum ServiceFunctionType {
  ADDITION_TO_TARIFF = 'addition_to_tariff',
  PERCENTAGE_FROM_TARIFF = 'percentage_from_tariff',
}

export interface Service {
  id: number;
  modified_at: string;
  created_at: string;
  name: string;
  service_type: ServiceType;
  value: number;
  additional_value: number;
  function: ServiceFunctionType;
  active: boolean;
}
