import { Properties } from 'types';

export interface ArticleTag {
  id: number;
  code: ArticleTags;
  i18n: Properties;
  timestamp: string;
  edited_timestamp: string;
}

export enum ArticleTags {
  PAGE = 'page',
  NEWS_PAGE = 'news-page',
  NEWS = 'news',
  NEWS_HOME_PAGE = 'news-home-page',
  NEWS_RELEVANCE = 'news-relevance',
  ANNOUNCES = 'announces',
  WARNINGS = 'warnings',
  PROMOTIONS = 'promotions',
  ABOUT = 'about',
  ABOUT_PAGE = 'about-page',
  MENU = 'menu',
  MENU_FOOTER = 'menu-footer',
  POPULAR_PAGE = 'popular-page',
  CONTACTS = 'contact',
  INFORMATION = 'information',
  SLIDER = 'slider',
  TEAM = 'team',
  TEAM_MEMBER = 'team-member',
  SCHEMA = 'schema',
  MAP = 'map',
  SITEMAP = 'site-map',
  POST_OFFICE = 'post-office',
  VACANCIES_PAGE = 'vacancies-page',
  COMPLAINT_PETITION = 'complaint-petition',
  COMPLAINT = 'complaint',
  COMPLAINT_INTERNATIONAL = 'complaint-international',
  PETITION = 'petition',
  PARENT_PAGE = 'parent-page',
  F_A_Q = 'frequently-asked-question',
  TRACK_TRACE = 'track-trace',
  TARIFF_CALCULATOR = 'tariff-calculator',
  FULLSCREEN_PAGE = 'fullscreen-page',
  MAILING_CALCULATOR = 'mailing-calculator',
  MANDATE_CALCULATOR = 'mandate-calculator',
  MARKET = 'market',
  PERIODIC_EDITIONS = 'periodic-editions',
}
