import { axios, cancelTokenHandler } from 'libs';
import { Properties } from 'types';

export const googleMap = {
  getMap: async ({ queryKey: [, value] }: Properties) => {
    const { data } = await axios.get(
      `https://nominatim.openstreetmap.org/search?format=json&limit=1&q=${value}`,
      {
        cancelToken: cancelTokenHandler(googleMap)(googleMap.getMap.name),
      },
    );

    return data;
  },
  getGeolocation: async (value: string) => {
    const { data } = await axios.get(
      `https://nominatim.openstreetmap.org/search?q=${value.replaceAll(
        ' ',
        '+',
      )}&format=json&polygon=1&addressdetails=1`,
      {
        headers: {},
        cancelToken: cancelTokenHandler(googleMap)(googleMap.getMap.name),
      },
    );

    return data;
  },
};
