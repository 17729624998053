import { axios, cancelTokenHandler } from 'libs';
import { stringifyUrl } from 'utils';
import { Properties } from 'types';

export const leadership = {
  getList: async ({ queryKey: [, query] }: Properties) => {
    const { data } = await axios.get(stringifyUrl(`leadership`, query, 2), {
      cancelToken: cancelTokenHandler(leadership)(leadership.getList.name),
    });

    return data;
  },
};
