import { axios, cancelTokenHandler } from 'libs';
import { stringifyUrl } from 'utils';
import { Complaint, Properties } from 'types';

export const complaints = {
  getList: async ({ queryKey: [, query] }: Properties) => {
    const { data } = await axios.get(stringifyUrl(`complaint/list`, query, 2), {
      cancelToken: cancelTokenHandler(complaints)(complaints.getList.name),
    });

    return data;
  },
  get: async ({ id, ...params }: Properties) => {
    const { data } = await axios.get(stringifyUrl(`complaint/${id}`, params, 2), {
      cancelToken: cancelTokenHandler(complaints)(complaints.get.name),
    });

    return data;
  },
  create: async (params: Partial<Complaint>) => {
    const { data } = await axios.post(stringifyUrl(`complaint`, null, 2), params, {
      cancelToken: cancelTokenHandler(complaints)(complaints.create.name),
    });

    return data;
  },
  update: async ({ id, ...params }: Partial<Complaint>) => {
    const { data } = await axios.put(stringifyUrl(`complaint/${id}`, null, 2), params, {
      cancelToken: cancelTokenHandler(complaints)(complaints.update.name),
    });

    return data;
  },
  updatePartial: async ({ id, ...params }: Partial<Complaint>) => {
    const { data } = await axios.patch(stringifyUrl(`complaint/${id}`, null, 2), params, {
      cancelToken: cancelTokenHandler(complaints)(complaints.updatePartial.name),
    });

    return data;
  },
  delete: async (id: number) => {
    const { data } = await axios.delete(stringifyUrl(`complaint/${id}`, null, 2), {
      cancelToken: cancelTokenHandler(complaints)(complaints.delete.name),
    });

    return data;
  },
};
