import { useCallback, useEffect, useState } from 'react';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import ReactGA from 'react-ga4';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'libs';
import { defaultOptions, getSeoTitle } from 'utils';

const Dictionary = dynamic(() => import('context/Dictionary'));
const Language = dynamic(() => import('context/Language'));
const Store = dynamic(() => import('context/Store'));
const Translate = dynamic(() => import('context/Translate'));

const DeployWrapper = dynamic(() => import('components/DeployWrapper'));
const Loader = dynamic(() => import('components/Loader'));
const Layout = dynamic(() => import('components/Layout'));
const Header = dynamic(() => import('components/Layout/Header'));
const Body = dynamic(() => import('components/Layout/Body'));
const Footer = dynamic(() => import('components/Layout/Footer'));

process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS &&
  ReactGA.initialize(process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS);

import 'styles/index.scss';

const App = ({ Component, pageProps }) => {
  const router = useRouter();
  const [queryClient] = useState(() => new QueryClient(defaultOptions));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);

    router.events.on('routeChangeComplete', pageView);
    return () => {
      router.events.off('routeChangeComplete', pageView);
    };
  }, [router]);

  useEffect(() => {
    pageView(router.asPath);
  }, []);

  const pageView = useCallback((url) => ReactGA.send({ hitType: 'pageview', page: url }), []);
  const handleStart = useCallback((url) => setLoading(url !== router.asPath), [router.asPath]);
  const handleComplete = useCallback(() => setLoading(false), []);

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <Dictionary>
          <Language>
            <Store>
              <Translate>
                <Head>
                  <title>{getSeoTitle()}</title>
                  <link rel="icon" href="/32X32.png" />
                  <meta name="viewport" content="width=device-width, initial-scale=1" />
                  <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
                  />
                </Head>
                <Layout>
                  <DeployWrapper>
                    <>
                      <Header />
                      <Body>
                        <Loader loading={loading}>
                          {!loading && <Component {...pageProps} />}
                        </Loader>
                      </Body>
                      <Footer />
                    </>
                  </DeployWrapper>
                </Layout>
              </Translate>
            </Store>
          </Language>
        </Dictionary>
      </Hydrate>
    </QueryClientProvider>
  );
};

export default App;
