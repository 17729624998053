import { axios, cancelTokenHandler } from 'libs';
import { stringifyUrl } from 'utils';
import { Faq, Properties, Results } from 'types';

export const faq = {
  getList: async ({ queryKey: [, query] }: Properties, headers?) => {
    const { data } = await axios.get<Results<Faq>>(stringifyUrl(`faq`, query), {
      headers,
      cancelToken: cancelTokenHandler(faq)(faq.getList.name),
    });

    return data;
  },
  get: async ({ id, ...params }: Properties) => {
    const { data } = await axios.get(stringifyUrl(`faq/${id}`, params), {
      cancelToken: cancelTokenHandler(faq)(faq.get.name),
    });

    return data;
  },
  create: async (params: Partial<Faq>) => {
    const { data } = await axios.post(stringifyUrl(`faq`, null), params, {
      cancelToken: cancelTokenHandler(faq)(faq.create.name),
    });

    return data;
  },
  update: async ({ id, ...params }: Partial<Faq>) => {
    const { data } = await axios.put(stringifyUrl(`faq/${id}`, null), params, {
      cancelToken: cancelTokenHandler(faq)(faq.update.name),
    });

    return data;
  },
  updatePartial: async ({ id, ...params }: Partial<Faq>) => {
    const { data } = await axios.patch(stringifyUrl(`faq/${id}`, null), params, {
      cancelToken: cancelTokenHandler(faq)(faq.updatePartial.name),
    });

    return data;
  },
  delete: async (id: number) => {
    const { data } = await axios.delete(stringifyUrl(`faq/${id}`, null), {
      cancelToken: cancelTokenHandler(faq)(faq.delete.name),
    });

    return data;
  },
};
