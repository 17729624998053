import { axios, cancelTokenHandler } from 'libs';
import { stringifyUrl } from 'utils';
import { Newsletter, Properties, Results } from 'types';

export const newsletter = {
  getList: async ({ queryKey: [, query] }: Properties) => {
    const { data } = await axios.get<Results<Newsletter>>(stringifyUrl(`newsletter-email`, query), {
      cancelToken: cancelTokenHandler(newsletter)(newsletter.getList.name),
    });

    return data;
  },
  get: async ({ id, ...params }: Properties) => {
    const { data } = await axios.get(stringifyUrl(`newsletter-email/${id}`, params), {
      cancelToken: cancelTokenHandler(newsletter)(newsletter.get.name),
    });

    return data;
  },
  subscribe: async (params: Partial<Newsletter>) => {
    const { data } = await axios.post(stringifyUrl(`newsletter-email`, null), params, {
      cancelToken: cancelTokenHandler(newsletter)(newsletter.subscribe.name),
    });

    return data;
  },
  unsubscribe: async (params: Partial<Newsletter>) => {
    const { data } = await axios.post(stringifyUrl(`newsletter-email/unsubscribe`, null), params, {
      cancelToken: cancelTokenHandler(newsletter)(newsletter.unsubscribe.name),
    });

    return data;
  },
  deleteTokenReceiver: async (token: number) => {
    const { data } = await axios.delete(
      stringifyUrl(`newsletter-email/token-receiver/${token}`, null),
      {
        cancelToken: cancelTokenHandler(newsletter)(newsletter.deleteTokenReceiver.name),
      },
    );

    return data;
  },
  delete: async (id: number) => {
    const { data } = await axios.delete(stringifyUrl(`newsletter-email/${id}`, null), {
      cancelToken: cancelTokenHandler(newsletter)(newsletter.delete.name),
    });

    return data;
  },
};
