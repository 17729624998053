export interface EcommerceCheckTariffTransfer {
  transfer_type: TransferType;
  additional_service: string | null;
  items: TransferItem[];
}

export enum TransferType {
  PERSON_TO_PERSON = 'person_to_person',
  PERSON_TO_COMPANY = 'person_to_company',
  COMPANY_TO_PERSON = 'company_to_person',
}

interface TransferItem {
  transfer_number?: string;
  value?: number;
  tariff_code?: string;
  effective_sender_name?: string;
  receiver_name?: string;
  receiver_organisation_name?: string;
  receiver_phone_number?: number;
  receiver_email?: string;
  receiver_registration_number?: string;
  receiver_iban?: string;
  receiver_birthday?: string;
  receiver_address?: number;
}
