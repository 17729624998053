import { axios, cancelTokenHandler } from 'libs';
import { stringifyUrl } from 'utils';
import { JsonString, Properties, Results } from 'types';

export const v1_jsonString = {
  getList: async ({ queryKey: [, query] }: Properties) => {
    const { data } = await axios.get<Results<JsonString>>(stringifyUrl(`json-string`, query), {
      cancelToken: cancelTokenHandler(v1_jsonString)(v1_jsonString.getList.name),
    });

    return data;
  },
  get: async ({ id, ...params }: Properties) => {
    const { data } = await axios.get(stringifyUrl(`json-string/${id}`, params), {
      cancelToken: cancelTokenHandler(v1_jsonString)(v1_jsonString.get.name),
    });

    return data;
  },
  create: async (params: Partial<JsonString>) => {
    const { data } = await axios.post(stringifyUrl(`json-string`), params, {
      cancelToken: cancelTokenHandler(v1_jsonString)(v1_jsonString.create.name),
    });

    return data;
  },
  updatePartial: async ({ id, ...params }: Partial<JsonString>) => {
    const { data } = await axios.patch(stringifyUrl(`json-string/${id}`), params, {
      cancelToken: cancelTokenHandler(v1_jsonString)(v1_jsonString.updatePartial.name),
    });

    return data;
  },
  delete: async (id: number) => {
    const { data } = await axios.delete(stringifyUrl(`json-string/${id}`), {
      cancelToken: cancelTokenHandler(v1_jsonString)(v1_jsonString.delete.name),
    });

    return data;
  },
};
