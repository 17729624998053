export interface $Object<T = string> {
  [key: string]: T;
}

/* eslint-disable */
export const has = (baseObj: { [key: string]: any }, pathToAttr: string, defaultValue?: any) =>
  (typeof pathToAttr === 'string' &&
    pathToAttr
      .replace(/\[|\]\[|\]\./g, '.')
      .split('.')
      .reduce((prev, curr) => prev && prev[curr], baseObj)) ||
  defaultValue ||
  undefined;
/* eslint-enable */

type ValidateStatus = '' | 'error' | 'success' | 'warning' | 'validating';

interface ValidateReturn {
  validateStatus: ValidateStatus;
  help: string | number | boolean;
}

export const validate = (
  errors: { [key: string]: string | number | boolean },
  key: string,
  validateStatus?: ValidateStatus,
): ValidateReturn | {} =>
  errors[key]
    ? {
        validateStatus: validateStatus || 'error',
        help: errors[key],
      }
    : {};

export function keys<O>(o: O): (keyof O)[] {
  return Object.keys(o) as (keyof O)[];
}

export const parseObject = (obj: $Object<string>): string => {
  const str = keys(obj).reduce<string[]>(
    (acc, current) => [...acc, `${current}=${obj[current]}`],
    [],
  );
  return `${str.length > 0 ? '?' : ''}${str.join('&')}`;
};
