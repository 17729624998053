import { axios, cancelTokenHandler } from 'libs';
import { stringifyUrl } from 'utils';
import { Properties, Tariff } from 'types';

export const tariffs = {
  getList: async ({ queryKey: [, query] }: Properties) => {
    const { data } = await axios.get<Tariff[]>(
      stringifyUrl(`${process.env.NEXT_PUBLIC_POSTAL_OFFICES}/public/tariffs`, query),
      {
        cancelToken: cancelTokenHandler(tariffs)(tariffs.getList.name),
      },
    );

    return data;
  },
  getGroupedList: async ({ queryKey: [, query] }: Properties) => {
    const { data } = await axios.get<Tariff[]>(
      stringifyUrl(`${process.env.NEXT_PUBLIC_POSTAL_OFFICES}/public/tariffs/grouped`, query),
      {
        cancelToken: cancelTokenHandler(tariffs)(tariffs.getGroupedList.name),
      },
    );

    return data;
  },
  calculate: async (params: Properties) => {
    const { data } = await axios.post(
      stringifyUrl(`${process.env.NEXT_PUBLIC_POSTAL_OFFICES}/public/tariffs/calculate`),
      params,
      {
        cancelToken: cancelTokenHandler(tariffs)(tariffs.calculate.name),
      },
    );

    return data;
  },
};
