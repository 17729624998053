import { axios, cancelTokenHandler } from 'libs';
import { Properties } from 'types';
import { default as queryString } from 'query-string';

export const postalOffices = {
  getList: async ({ queryKey: [, query] }: Properties) => {
    const { data } = await axios.get(
      queryString.stringifyUrl(
        {
          url: `${process.env.NEXT_PUBLIC_MAIN_POSTAL_OFFICES}/nomenclatures/postal-offices`,
          ...(query && { query }),
        },
        { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' },
      ),
      {
        cancelToken: cancelTokenHandler(postalOffices)(postalOffices.getList.name),
      },
    );

    return data;
  },
};

export const searchPostalOffice = async ({ queryKey: [, query] }: Properties) => {
  const { data } = await axios.get(
    queryString.stringifyUrl(
      {
        url: `${process.env.NEXT_PUBLIC_MAIN_POSTAL_OFFICES}/nomenclatures/postal-offices`,
        query: { ...query },
      },
      { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' },
    ),
  );
  return data;
};

export const postalRegions = async ({ queryKey: [, query] }: Properties) => {
  const { data } = await axios.get(
    queryString.stringifyUrl(
      {
        url: `${process.env.NEXT_PUBLIC_MAIN_POSTAL_OFFICES}/nomenclatures/regions`,
        query: { ...query },
      },
      { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' },
    ),
  );
  return data;
};

export const postalCities = async ({ queryKey: [, query] }: Properties) => {
  const { data } = await axios.get(
    queryString.stringifyUrl(
      {
        url: `${process.env.NEXT_PUBLIC_MAIN_POSTAL_OFFICES}/nomenclatures/cities`,
        query: { ...query },
      },
      { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' },
    ),
  );

  return data;
};

export const postalBlocks = async ({ queryKey: [, query], page }: Properties) => {
  const { data } = await axios.get(
    queryString.stringifyUrl(
      {
        url: `${process.env.NEXT_PUBLIC_MAIN_POSTAL_OFFICES}/nomenclatures/blocks`,
        query: { ...query, page },
      },
      { skipEmptyString: true, skipNull: true, arrayFormat: 'comma', encode: false },
    ),
  );

  return data;
};

export const postalStreets = async ({ queryKey: [, query], page }: Properties) => {
  const { data } = await axios.get(
    queryString.stringifyUrl(
      {
        url: `${process.env.NEXT_PUBLIC_MAIN_POSTAL_OFFICES}/nomenclatures/streets`,
        query: { ...query, page },
      },
      { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' },
    ),
  );

  return data;
};

export const postalOfficesList = async ({ queryKey: [, query], page }: Properties) => {
  const { data } = await axios.get(
    queryString.stringifyUrl(
      {
        url: `${process.env.NEXT_PUBLIC_MAIN_POSTAL_OFFICES}/nomenclatures/postal-offices/list`,
        query: { ...query, page },
      },
      { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' },
    ),
  );

  return data;
};
