export interface TariffWeight {
  id: number;
  weight: number;
  value: number;
  tariff: number;
  modified_at: string;
  created_at: string;
}

export enum TariffCategory {
  STANDARD = 'standard',
  NON_STANDARD = 'non_standard',
  PALLETS = 'pallets',
  TARIFF_PER_VISIT = 'tariff_per_visit',
  FIXED_TARIFF = 'fixed_tariff',
}

export enum DeliveryType {
  LOCAL = 'local',
  NATIONAL = 'national',
}

export enum PickupLocationType {
  ADDRESS = 'address',
  POSTAL_OFFICE = 'postal_office',
  POSTAL_TERMINAL = 'postal_terminal',
}

export interface Tariff {
  id: number;
  weights: TariffWeight[];
  category: TariffCategory;
  delivery_type: DeliveryType;
  sender_pickup_location: PickupLocationType;
  receiver_pickup_location: PickupLocationType;
  value: number;
  modified_at: string;
  created_at: string;
}
