import { axios, cancelTokenHandler } from 'libs';
import { stringifyUrl } from 'utils';
import { Captcha, CaptchaGoogleValidate, CaptchaValidate } from 'types';

export const captcha = {
  create: async (): Promise<Captcha> => {
    const { data } = await axios.post(
      stringifyUrl(`captcha`, null, 2),
      {},
      {
        cancelToken: cancelTokenHandler(captcha)(captcha.create.name),
      },
    );

    return data;
  },
  validateGoogle: async (params: Partial<CaptchaGoogleValidate>) => {
    const { data } = await axios.post(stringifyUrl(`captcha/google/validate`, null, 2), params, {
      cancelToken: cancelTokenHandler(captcha)(captcha.validate.name),
    });

    return data;
  },
  validate: async (params: Partial<CaptchaValidate>) => {
    const { data } = await axios.post(stringifyUrl(`captcha/validate`, null, 2), params, {
      cancelToken: cancelTokenHandler(captcha)(captcha.validate.name),
    });

    return data;
  },
};
