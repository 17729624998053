import { trimHTML, trimText } from 'utils';

export const getSeoTitle = (text?: string): string =>
  trimText(
    ['Posta Moldovei', (text && ` - ${text}`) || ''].join(''),
    process.env.NEXT_PUBLIC_SEO_TITLE_LENGTH && parseInt(process.env.NEXT_PUBLIC_SEO_TITLE_LENGTH),
  );
export const getSeoDescription = (text?: string): string =>
  trimText(
    trimHTML(text),
    process.env.NEXT_PUBLIC_SEO_DESCRIPTION_LENGTH &&
      parseInt(process.env.NEXT_PUBLIC_SEO_DESCRIPTION_LENGTH),
  );
