import { axios, cancelTokenHandler } from 'libs';
import { stringifyUrl } from 'utils';
import { Properties } from 'types';

export const mailing = {
  getTariffsFields: async ({ queryKey: [, query] }: Properties) => {
    const { data } = await axios.get<Properties>(
      stringifyUrl(
        `${process.env.NEXT_PUBLIC_SENDING_POSTAL_OFFICES}/public/shipping/tariffs/fields`,
        query,
      ),
      {
        cancelToken: cancelTokenHandler(mailing)(mailing.getTariffsFields.name),
      },
    );

    return data;
  },
  calculate: async (params: Properties) => {
    const { data } = await axios.post(
      stringifyUrl(
        `${process.env.NEXT_PUBLIC_SENDING_POSTAL_OFFICES}/public/shipping/tariffs/calculate`,
      ),
      params,
      {
        cancelToken: cancelTokenHandler(mailing)(mailing.calculate.name),
      },
    );

    return data;
  },
};
