import { routes } from '../../../routes';

const getInitialQueries = (route: string, lang: string) => {
  const $queries = {} as { [key: string]: any };

  if (routes[route] && routes[route][lang]) {
    Object.keys(routes[route][lang]).forEach((key) => {
      $queries[key] = null;
    });
  }

  return $queries;
};

export const parseQuery = (
  route: string,
  queries: { [key: string]: any },
): { [key: string]: any } => {
  const parsedQuery = getInitialQueries(route, queries.lang);
  const unparsedQuery = {} as { [key: string]: any };

  const queryKeys = Object.keys(parsedQuery).filter((key) => key !== 'lang');

  queryKeys.forEach((key) => {
    if (routes[route]) {
      const query = routes[route][queries.lang][key] as { [key: string]: any };

      if (query) {
        Object.keys(query).forEach((subKey) => {
          if (query.parse === false) {
            parsedQuery[key] = queries[key];
          } else if (query[subKey].default) {
            parsedQuery[key] = query[subKey].default;
          } else if (queries[key] === query[subKey]) {
            parsedQuery[key] = subKey;
          }
        });
      }
    }
  });

  Object.keys(queries)
    .filter((key) => !queryKeys.includes(key))
    .forEach((key) => (unparsedQuery[key] = queries[key]));

  return { ...unparsedQuery, ...parsedQuery };
};

export const unparseQuery = (
  route: string,
  queries: { [key: string]: string },
): { [key: string]: any } => {
  const $queries = getInitialQueries(route, queries.lang);

  const queryKeys = Object.keys($queries).filter((key) => key !== 'lang');

  if (typeof queries.lang === 'string') {
    queryKeys.forEach((key) => {
      if (routes[route][queries.lang][key].parse === false) {
        $queries[key] = queries[key];
      } else if (routes[route][queries.lang][key].default) {
        $queries[key] = routes[route][queries.lang][key].default;
      } else {
        $queries[key] = routes[route][queries.lang][key][queries[key]];
      }
    });
  }

  return { lang: queries.lang, ...$queries };
};

export const getHrefPathname = (route: string, params: { [key: string]: any }) => {
  // exception for index page which obviously doesn't have a route
  if (route === 'index') {
    return '/[lang]';
  }

  const defaultPath = `/[lang]/${route}`;

  const path = Object.keys(routes[route][params.lang])
    .map((key) => `[${key}]`)
    .join('/');

  return `${defaultPath}${path ? '/' : ''}${path}`;
};

export const getAsPathname = (route: string, $params: { [key: string]: any }) => {
  // exception for index page which obviously doesn't have a route
  if (route === 'index') {
    return `/${$params.lang}`;
  }

  const params = unparseQuery(route, $params) as any;

  const defaultPath = `/${$params.lang}/${route}`;

  const path = Object.keys(params)
    .filter((key) => key !== 'lang')
    .map((key) => params[key])
    .join('/');

  return `${defaultPath}${path ? '/' : ''}${path}`;
};

export const getRouteName = (asPath: string) => {
  // exception for index page which obviously doesn't have a route
  if (asPath && (asPath === '/' || asPath.length === 3 || asPath.length === 4)) {
    return 'index';
  }

  return Object.keys(routes).find((key) => asPath.match(`/${key}/`)) || '';
};
