import { defaultDictionaries } from './DictionaryContext';
import { DictionaryStoreType, locales } from './index';

export const reducer = (state: DictionaryStoreType, action: Action): DictionaryStoreType => {
  if (!action.dictionary) {
    throw new Error('Dictionary not provided.');
  }

  switch (action.type) {
    case ActionType.ADD: {
      return mergeDictionaries([state, action.dictionary!]);
    }
    case ActionType.REPLACE: {
      return action.dictionary;
    }
    default:
      throw new Error('Unexpected action type.');
  }
};

export const init = (store: DictionaryStoreType): DictionaryStoreType => {
  const clone = { ...store };
  locales.forEach((l) => {
    if (!clone[l]) {
      clone[l] = {};
    }
  });

  return clone;
};

export enum ActionType {
  ADD = 0,
  REPLACE = 1,
}

export interface Action {
  type: ActionType;
  dictionary?: DictionaryStoreType;
}

type MDT = (dictionaries: DictionaryStoreType[]) => DictionaryStoreType;

export const mergeDictionaries: MDT = (dictionaries) => {
  const initialValue: DictionaryStoreType = { ...defaultDictionaries };
  const dictionary: DictionaryStoreType = locales.reduce((acc: DictionaryStoreType, l) => {
    acc[l] = {};
    return acc;
  }, initialValue);

  dictionaries.forEach((d) => {
    locales.forEach((l) => {
      if (typeof d[l] === 'object') {
        Object.keys(d[l]).forEach((ns) => {
          dictionary[l][ns] = { ...d[l][ns] };
        });
      }
    });
  });

  return dictionary;
};
