import { axios, cancelTokenHandler } from 'libs';
import { stringifyUrl } from 'utils';
import { Properties, Service, Results } from 'types';

export const services = {
  getList: async ({ queryKey: [, query] }: Properties) => {
    const { data } = await axios.get<Results<Service>>(
      stringifyUrl(`${process.env.NEXT_PUBLIC_POSTAL_OFFICES}/public/additional-services`, query),
      {
        cancelToken: cancelTokenHandler(services)(services.getList.name),
      },
    );

    return data;
  },
};
