import { createContext, Dispatch } from 'react';
import { DictionaryStoreType } from 'context/Dictionary';
import { Locale, Properties } from 'types';

import { Action } from './utils';

interface DictionaryContextValue {
  store: DictionaryStoreType;
  dispatch: Dispatch<Action>;
  locale: Locale;
  loading: boolean;
  activeLanguages?: string[];
}

export const defaultDictionaries = { ro: {}, ru: {}, en: {} };

/**
 * `DictionaryContext` is the locale context used for i18n-ing the app.
 *
 * It contains the current locale and provide a function to update it.
 */
const DictionaryContext = createContext<DictionaryContextValue>({
  store: { ...defaultDictionaries },
  dispatch: () => {
    /** noop */
  },
  locale: Locale.RO,
  loading: false,
  activeLanguages: [],
});

export default DictionaryContext;
