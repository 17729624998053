import { Article } from './articles';
import { AddressType, PostalOfficeType } from './nomenclatures';

export type Properties<Data = any> = Record<string, Data>;

export enum PetitionComplaintType {
  PETITION = '1',
  COMPLAINT_INTERN = '2',
  COMPLAINT_EXTERN = '3',
}

export enum PersonType {
  PHYSICS = '1',
  LEGALLY = '2',
}

export enum ReplyType {
  BY_PHONE = '1',
  BY_EMAIL = '2',
  BY_POST = '3',
}

export enum ReasonsType {
  SEARCH_POSTAL_ITEM = '1',
  MISSING_DAMAGED_CONTENT = '2',
  DISAGREE_WITH_RETURN = '3',
  LACK_ACKNOWLEDGMENT_RECEIPT = '4',
  NON_RECEIPT_REFUND_AMOUNT = '5',
  ANOTHER_REASON = '6',
}

export enum ReferenceType {
  LETTER = 'letter',
  EMS = 'ems',
  PARCEL = 'parcel',
  SMALL_PACKAGE = 'small-package',
  PRINTED = 'printed',
}

export enum BannerType {
  HEAD_BANNER = '1',
  ADVERTISING_BANNER = '2',
  SPECIAL_PAGE_BANNER = '3',
}

export interface BaseProps extends React.HTMLAttributes<HTMLDivElement> {
  t?: string;
}

export type VersionType = 1 | 2;
// export type PersonType = VersionType;
export type StateType = VersionType | 3;
export type JobStateType = VersionType;

// export type ReasonType = StateType | 4 | 5 | 6;

export interface Item {
  id: number;
  name: string;
}

export interface ItemWithCode extends Item {
  code: string;
}

export interface RegionResponse {
  id: string;
  modified_at: string;
  created_at: string;
  name: string;
  region: number;
  shipping_zone: null;
}

export interface MenuItem extends Article {
  children: MenuItem[];
}

export interface Filters {
  page: number;
  page_size: number;
}

export interface LeadershipMembers {
  data: any;
  edited_timestamp: string;
  timestamp: string;
  type: string;
  id: number;
  members: [
    {
      first_name: string;
      last_name: string;
      picture: string;
      role: string;
      i18n: LeadershipI18n;
      facebook?: string;
      linked_in?: string;
      link_to_cv?: string;
    },
  ];
}

export interface LeadershipI18n {
  ro: MembersProps;
  en: MembersProps;
  ru: MembersProps;
}

interface MembersProps {
  first_name: string;
  last_name: string;
  role: string;
}

export interface Results<T> {
  count: number;
  next: string;
  previous: string;
  results: T[];
}

export enum Locale {
  EN = 'en',
  RO = 'ro',
  RU = 'ru',
}

export interface SearchPostalOffice {
  ordering?: string;
  office_type?: string;
  zip_code?: string;
  region?: string;
  city?: string;
  search?: string;
  block?: string;
  street_name?: string;
}

export interface MainApiResults<T = any> {
  count: number;
  current_page: number;
  per_page: number;
  total_pages: number;
  results: T[];
}

export type ECommerceType = 'market' | 'periodic-editions';
