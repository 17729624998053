import axios from 'axios';

import { Item, Properties, MainApiResults, PeriodicEdition } from 'types';
import { stringifyUrl } from 'utils';

export const periodicEditions = {
  getList: async ({ queryKey: [, query] }: Properties, headers?) => {
    const { data } = await axios.get<MainApiResults<PeriodicEdition>>(
      stringifyUrl(
        `${process.env.NEXT_PUBLIC_MAIN_POSTAL_OFFICES}/periodic-editions/editions`,
        query,
      ),
      {
        headers,
      },
    );

    return data;
  },
  get: async ({ queryKey: [, { id, ...query }] }: Properties, headers?) => {
    const { data } = await axios.get<PeriodicEdition>(
      stringifyUrl(
        `${process.env.NEXT_PUBLIC_MAIN_POSTAL_OFFICES}/periodic-editions/editions/${id}`,
        query,
      ),
      {
        headers,
      },
    );

    return data;
  },
  getCategories: async ({ queryKey: [, query] }: Properties, headers?) => {
    const { data } = await axios.get<MainApiResults<Item>>(
      stringifyUrl(
        `${process.env.NEXT_PUBLIC_MAIN_POSTAL_OFFICES}/periodic-editions/editions/categories`,
        query,
      ),
      {
        headers,
      },
    );

    return data;
  },
  getTypes: async ({ queryKey: [, query] }: Properties, headers?) => {
    const { data } = await axios.get<MainApiResults<Item>>(
      stringifyUrl(
        `${process.env.NEXT_PUBLIC_MAIN_POSTAL_OFFICES}/periodic-editions/editions/types`,
        query,
      ),
      {
        headers,
      },
    );
    return data;
  },
  getCountries: async ({ queryKey: [, query] }: Properties, headers?) => {
    if (!query) query = {};
    query.has_in_editions = true;
    const { data } = await axios.get<MainApiResults<PeriodicEdition>>(
      stringifyUrl(`${process.env.NEXT_PUBLIC_MAIN_POSTAL_OFFICES}/nomenclatures/countries`, query),
      {
        headers,
      },
    );
    return data;
  },
};
