import { axios, cancelTokenHandler } from 'libs';
import { stringifyUrl } from 'utils';
import { Properties, PostalOffice } from 'types';

export const offices = {
  getList: async ({ queryKey: [, query] }: Properties) => {
    const { data } = await axios.get<PostalOffice[]>(
      stringifyUrl(
        `${process.env.NEXT_PUBLIC_MAIN_POSTAL_OFFICES}/nomenclatures/postal-offices`,
        query,
      ),
      {
        cancelToken: cancelTokenHandler(offices)(offices.getList.name),
      },
    );

    return data;
  },
};
