import { axios, cancelTokenHandler } from 'libs';
import { stringifyUrl } from 'utils';
import { ResetPasswordById, ResetPasswordByEmail, ResetPasswordTokenReceiver } from 'types';

export const resetPassword = {
  resetPass: async ({ id, ...params }: ResetPasswordById) => {
    const { data } = await axios.post(stringifyUrl(`user/${id}/reset-pass`), params, {
      cancelToken: cancelTokenHandler(resetPassword)(resetPassword.resetPass.name),
    });

    return data;
  },
  resetPasswordByEmail: async (params: ResetPasswordByEmail) => {
    const { data } = await axios.post(stringifyUrl(`user/reset-by-email`), params, {
      cancelToken: cancelTokenHandler(resetPassword)(resetPassword.resetPasswordByEmail.name),
    });

    return data;
  },
  confirmResetPassword: async ({ token, ...params }: ResetPasswordTokenReceiver) => {
    const { data } = await axios.post(stringifyUrl(`user/token_recevier/${token}`), params, {
      cancelToken: cancelTokenHandler(resetPassword)(resetPassword.confirmResetPassword.name),
    });

    return data;
  },
};
