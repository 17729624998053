import { axios, cancelTokenHandler } from 'libs';
import { stringifyUrl } from 'utils';
import { Properties, AdditionalServices, Results } from 'types';

export const mandate = {
  getAdditionalServices: async ({ queryKey: [, query] }: Properties) => {
    const { data } = await axios.get<Results<AdditionalServices>>(
      stringifyUrl(
        `${process.env.NEXT_PUBLIC_SENDING_POSTAL_OFFICES}/public/transfers/additional-services`,
        query,
      ),
      {
        cancelToken: cancelTokenHandler(mandate)(mandate.getAdditionalServices.name),
      },
    );

    return data;
  },
  calculate: async (params: Properties) => {
    const { data } = await axios.post(
      stringifyUrl(
        `${process.env.NEXT_PUBLIC_SENDING_POSTAL_OFFICES}/public/transfers/tariffs/calculate`,
      ),
      params,
      {
        cancelToken: cancelTokenHandler(mandate)(mandate.calculate.name),
      },
    );

    return data;
  },
};
