import { default as queryString } from 'query-string';
import { Properties, VersionType } from 'types';

export const stringifyUrl = (
  url: string,
  query: null | Properties = {},
  version: VersionType = 1,
): string => {
  return queryString.stringifyUrl(
    {
      url: url.match(/http/) ? url : `v${version}/${url}/`,
      ...(query && { query }),
    },
    { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' },
  );
};

export const defaultOptions = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    },
  },
};
