import { useRouter as useNextRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { parseQuery } from 'libs/router';
import { getRouteName } from 'libs/router/helpers';

export const useRouter = () => {
  const router = useNextRouter();
  const route = getRouteName(router.asPath);

  return { ...router, query: route ? (parseQuery(route, router.query) as ParsedUrlQuery) : {} };
};
