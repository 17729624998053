export interface Country {
  id: number;
  name: string;
  code: string;
  created_at: string;
  modified_at: string;
}

export interface City {
  id: number;
  name: string;
  region: number;
  shipping_zone: string;
  created_at: string;
  modified_at: string;
}

export interface Region {
  id: number;
  name: string;
  country: number;
  created_at: string;
  modified_at: string;
}

export interface Package {
  id: number;
  name: string;
  image: string;
  price: number;
  length: number;
  width: number;
  height: number;
  is_active: boolean;
  created_at: string;
  modified_at: string;
}

export type GeolocationType = {
  type: string;
  coordinates: number[];
};

export type AddressType = {
  id: number;
  region: Region;
  country: Country;
  city: City;
  street: string;
  zip_code: string;
  house: string;
  block: string;
  office: string;
  entrance: string;
  floor: string;
  entrance_code: string;
  geolocation: GeolocationType;
  created_at: string;
  modified_at: string;
};

export enum PostalOfficeType {
  POST_OFFICE = 'post_office',
  POST_AGENCY = 'post_agency',
  POST_CENTER = 'post_center',
  POST_TERMINAL = 'post_terminal',
  FAST_COURIER_CENTER = 'fast_courier_center',
  TRANSIT_CENTER = 'transit_center',
  DEPOSIT = 'deposit',
}

export interface PostalOffice {
  address: AddressType;
  streets: Streets;
  id: number;
  name: string;
  email?: string;
  office_type: PostalOfficeType;
  created_at: string;
  modified_at: string;
  work_days?: WorkDays;
  zip_code: string;
}
export interface Streets {
  city: City;
  created_at: string;
  modified_at: string;
  id: number;
  name?: string;
  postal_office?: number;
}
export interface WorkDays {
  work_hours_from: string;
  work_hours_to: string;
  break_hours_from?: string | null;
  break_hours_to?: string | null;
  days?: string[];
}
