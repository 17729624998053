import { axios, cancelTokenHandler } from 'libs';
import { stringifyUrl } from 'utils';
import { Language, Properties, Results } from 'types';

export const language = {
  getList: async ({ queryKey: [, query] }: Properties) => {
    const { data } = await axios.get<Results<Language>>(stringifyUrl(`language`, query), {
      cancelToken: cancelTokenHandler(language)(language.getList.name),
    });

    return data;
  },
  get: async ({ id, ...params }: Properties) => {
    const { data } = await axios.get(stringifyUrl(`language/${id}`, params), {
      cancelToken: cancelTokenHandler(language)(language.get.name),
    });

    return data;
  },
  create: async (params: Partial<Language>) => {
    const { data } = await axios.post(stringifyUrl(`language`, null), params, {
      cancelToken: cancelTokenHandler(language)(language.create.name),
    });

    return data;
  },
  update: async ({ id, ...params }: Partial<Language>) => {
    const { data } = await axios.put(stringifyUrl(`language/${id}`, null), params, {
      cancelToken: cancelTokenHandler(language)(language.update.name),
    });

    return data;
  },
  updatePartial: async ({ id, ...params }: Partial<Language>) => {
    const { data } = await axios.patch(stringifyUrl(`language/${id}`, null), params, {
      cancelToken: cancelTokenHandler(language)(language.updatePartial.name),
    });

    return data;
  },
  delete: async (id: number) => {
    const { data } = await axios.delete(stringifyUrl(`language/${id}`, null), {
      cancelToken: cancelTokenHandler(language)(language.delete.name),
    });

    return data;
  },
};
