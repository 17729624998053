import { axios, cancelTokenHandler } from 'libs';
import { stringifyUrl } from 'utils';
import { Job, Properties } from 'types';

export const jobs = {
  getList: async ({ queryKey: [, query] }: Properties, headers?) => {
    const { data } = await axios.get(stringifyUrl(`job`, query, 2), {
      headers,
      cancelToken: cancelTokenHandler(jobs)(jobs.getList.name),
    });

    return data;
  },
  get: async ({ id, ...params }: Properties) => {
    const { data } = await axios.get(stringifyUrl(`job/${id}`, params, 2), {
      cancelToken: cancelTokenHandler(jobs)(jobs.get.name),
    });

    return data;
  },
  create: async (params: Partial<Job>) => {
    const { data } = await axios.post(stringifyUrl(`job`, null, 2), params, {
      cancelToken: cancelTokenHandler(jobs)(jobs.create.name),
    });

    return data;
  },
  update: async ({ id, ...params }: Partial<Job>) => {
    const { data } = await axios.put(stringifyUrl(`job/${id}`, null, 2), params, {
      cancelToken: cancelTokenHandler(jobs)(jobs.update.name),
    });

    return data;
  },
  updatePartial: async ({ id, ...params }: Partial<Job>) => {
    const { data } = await axios.patch(stringifyUrl(`job/${id}`, null, 2), params, {
      cancelToken: cancelTokenHandler(jobs)(jobs.updatePartial.name),
    });

    return data;
  },
  delete: async (id: number) => {
    const { data } = await axios.delete(stringifyUrl(`job/${id}`, null, 2), {
      cancelToken: cancelTokenHandler(jobs)(jobs.delete.name),
    });

    return data;
  },
};
